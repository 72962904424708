<div class="content__inner">
    <header class="content__title">
        <h1>{{ 'webcasts.list.title' | translate }}</h1>
        <small>{{ 'webcasts.list.subtitle' | translate }}</small>


        <div class="actions"> 
            <button *appHasPermission="'WEBCASTS.ADD'" (click)="addWebcast()" class="btn btn-success btn--icon-text" style="margin-left:25px;"><i class="zmdi zmdi-collection-plus"></i> {{ 'webcasts.list.add' | translate }}</button>
        </div>
    </header>

    <div class="card" *appHasPermission="'WEBCASTS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ 'webcasts.list.card.title' | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="title">{{ 'webcasts.props.title' | translate }}</th>
                        <th id="category">{{ 'webcasts.props.category' | translate }}</th>
                        <th id="qsdnId">{{ 'webcasts.props.qsdnId' | translate }}</th>
                        <th id="sortNumber">{{ 'webcasts.props.sortNumber' | translate }}</th>
                        <th id="created">{{ 'webcasts.props.created' | translate }}</th>
                        <th id="updated">{{ 'webcasts.props.updated' | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.title }}</td>
                        <td>{{ row.category ? row.category.name : '-' }}</td>
                        <td>{{ row.qsdnId }}</td>
                        <td>{{ row.sortNumber }}</td>
                        <td>{{ row.created | date:UTCDATEFORMAT:'+0' }}</td>
                        <td>{{ row.updated | date:UTCDATEFORMAT:'+0' }}</td>
                        <td class="text-right nowrap">
                            <i (click)="editWebcast(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'WEBCASTS.EDIT'"></i>
                            <i (click)="deleteWebcast(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'WEBCASTS.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="7" class="no-data-available">{{ 'global.datatable.empty' | translate }}</td>
                    </tr>
                <tbody>
            </table>
        </div>
    </div>
</div>