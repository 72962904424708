<div class="content__inner">
    <header class="content__title">
        <h1>{{ 'interfacesettings.title' | translate }}</h1>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="interfaceSettings" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>

                <div class="col-md-12">
                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" [(ngModel)]="interfaceSettings.showFooterHotlineText" id="showFooterHotlineText" name="showFooterHotlineText" />
                            <label class="checkbox__label" for="showFooterHotlineText">{{ 'interfacesettings.props.showFooterHotlineText' | translate }}</label>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" [(ngModel)]="interfaceSettings.showSponsorsPage" id="showSponsorsPage" name="showSponsorsPage" />
                            <label class="checkbox__label" for="showSponsorsPage">{{ 'interfacesettings.props.showSponsorsPage' | translate }}</label>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" [(ngModel)]="interfaceSettings.showOnDemandPage" id="showOnDemandPage" name="showOnDemandPage" />
                            <label class="checkbox__label" for="showOnDemandPage">{{ 'interfacesettings.props.showOnDemandPage' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" [(ngModel)]="interfaceSettings.showProgramRegistrationDay1" id="showProgramRegistrationDay1" name="showProgramRegistrationDay1" />
                            <label class="checkbox__label" for="showProgramRegistrationDay1">{{ 'interfacesettings.props.showProgramRegistrationDay1' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" [(ngModel)]="interfaceSettings.showProgramLiveJoinDay1" id="showProgramLiveJoinDay1" name="showProgramLiveJoinDay1" />
                            <label class="checkbox__label" for="showProgramLiveJoinDay1">{{ 'interfacesettings.props.showProgramLiveJoinDay1' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" [(ngModel)]="interfaceSettings.showProgramRegistrationDay2" id="showProgramRegistrationDay2" name="showProgramRegistrationDay2" />
                            <label class="checkbox__label" for="showProgramRegistrationDay2">{{ 'interfacesettings.props.showProgramRegistrationDay2' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" [(ngModel)]="interfaceSettings.showProgramLiveJoinDay2" id="showProgramLiveJoinDay2" name="showProgramLiveJoinDay2" />
                            <label class="checkbox__label" for="showProgramLiveJoinDay2">{{ 'interfacesettings.props.showProgramLiveJoinDay2' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" [(ngModel)]="interfaceSettings.showProgramRegistrationDay3" id="showProgramRegistrationDay3" name="showProgramRegistrationDay3" />
                            <label class="checkbox__label" for="showProgramRegistrationDay3">{{ 'interfacesettings.props.showProgramRegistrationDay3' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" [(ngModel)]="interfaceSettings.showProgramLiveJoinDay3" id="showProgramLiveJoinDay3" name="showProgramLiveJoinDay3" />
                            <label class="checkbox__label" for="showProgramLiveJoinDay3">{{ 'interfacesettings.props.showProgramLiveJoinDay3' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" *appHasPermission="'INTERFACESETTINGS.EDIT'">
                    <div class="row">
                        <div class="col-12">
                            <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ 'global.buttons.save' | translate }}</button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>