import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class AppService {
    // Default theme
    maTheme = "blue-grey";

    // Mobile sidebar state
    mobileSidebarActive = false;

    constructor() {}
}
