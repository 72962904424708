import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { WebcastsService } from "./webcasts.service";
import { TranslateService } from "@ngx-translate/core";
import { Webcast } from "../../api-models/Webcast";
var WebcastDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WebcastDetailComponent, _super);
    function WebcastDetailComponent(route, webcastsService, router, alertService, translateService, _location) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.webcastsService = webcastsService;
        _this.router = router;
        _this.alertService = alertService;
        _this.translateService = translateService;
        _this._location = _location;
        _this.webcastId = null;
        _this.webcast = null;
        _this.categories = [];
        _this.subscriptions.push(_this.route.params.subscribe(function (params) {
            _this.webcastId = params.id;
            if (_this.webcastId) {
                _this.initEditMode();
            }
            else {
                _this.initAddMode();
            }
        }));
        return _this;
    }
    WebcastDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.webcastsService.allCategories().subscribe(function (result) {
            _this.categories = result;
        }));
    };
    WebcastDetailComponent.prototype.initAddMode = function () {
        this.MODE = BaseComponentMode.ADD;
        this.webcast = new Webcast();
    };
    WebcastDetailComponent.prototype.initEditMode = function () {
        var _this = this;
        this.MODE = BaseComponentMode.EDIT;
        this.subscriptions.push(this.webcastsService.webcastdetail(this.webcastId).subscribe(function (result) {
            _this.webcast = result;
        }, function (err) {
            _this.router.navigate(["../"], { relativeTo: _this.route });
        }));
    };
    WebcastDetailComponent.prototype.save = function () {
        var _this = this;
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(this.webcastsService.addwebcast(this.webcast).subscribe(function (result) {
                _this.alertService.addSuccess(_this.translateService.instant("webcasts.detail.add.success"));
                _this.router.navigate(["../"], { relativeTo: _this.route });
            }));
        }
        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(this.webcastsService.updatewebcast(this.webcast).subscribe(function (result) {
                _this.alertService.addSuccess(_this.translateService.instant("webcasts.detail.edit.success"));
                _this.router.navigate(["../"], { relativeTo: _this.route });
            }));
        }
    };
    WebcastDetailComponent.prototype.back = function () {
        this._location.back();
    };
    return WebcastDetailComponent;
}(BaseComponent));
export { WebcastDetailComponent };
