import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

import { InterfaceSettingsService, IInterfaceSettingsConfigService, InterfaceSettingsConfigService } from "./interfacesettings.service";
import { InterfaceSettingsComponent } from "./interfacesettings.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route } from "@angular/router";

@NgModule({
    declarations: [InterfaceSettingsComponent],
    imports: [CommonModule, FormsModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class InterfaceSettingsModule {
    static forRoot(interfaceSettingsConfig: IInterfaceSettingsConfigService): ModuleWithProviders {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: InterfaceSettingsModule,
            providers: [
                InterfaceSettingsService,
                {
                    provide: InterfaceSettingsConfigService,
                    useValue: interfaceSettingsConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: InterfaceSettingsComponent
            }
        ];
    }
}
