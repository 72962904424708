import * as i0 from "@angular/core";
var AppService = /** @class */ (function () {
    function AppService() {
        // Default theme
        this.maTheme = "blue-grey";
        // Mobile sidebar state
        this.mobileSidebarActive = false;
    }
    AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
