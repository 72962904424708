import * as tslib_1 from "tslib";
import { BaseComponent } from "@impacgroup/angular-baselib";
var WebcastsMainComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WebcastsMainComponent, _super);
    function WebcastsMainComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WebcastsMainComponent;
}(BaseComponent));
export { WebcastsMainComponent };
