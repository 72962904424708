import { Component, OnInit } from "@angular/core";
import { AlertService } from "@impacgroup/angular-baselib";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"]
})
export class SingleLayoutComponent implements OnInit {

  constructor(public alertService: AlertService) { }

  ngOnInit() {
  }

}
