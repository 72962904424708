<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ 'categories.detail.add.title' | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ 'categories.detail.edit.title' | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i>
                {{ 'global.buttons.back' | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="category" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12">

                    <div class="form-group">
                        <label>{{ 'categories.props.name' | translate }}</label>
                        <input name="name" type="text" class="form-control" [(ngModel)]="category.name" #name="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'categories.detail.validation.name' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-12">

                    <div class="form-group">
                        <label>{{ 'categories.props.sortNumber' | translate }}</label>
                        <input name="sortNumber" type="number" class="form-control" [(ngModel)]="category.sortNumber" #sortNumber="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && sortNumber.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'categories.detail.validation.sortNumber' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i
                            class="zmdi zmdi-check"></i> {{ 'global.buttons.save' | translate }}</button>
                </div>

            </form>
        </div>
    </div>
</div>