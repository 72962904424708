import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { WebcastsService } from "./webcasts.service";
import { TranslateService } from "@ngx-translate/core";
import { Webcast } from "../../api-models/Webcast";

@Component({
    selector: "app-webcastdetail",
    templateUrl: "./webcastdetail.component.html",
    styleUrls: ["webcastdetail.component.scss"]
})
export class WebcastDetailComponent extends BaseComponent implements OnInit {
    private webcastId: string = null;
    public webcast: Webcast = null;
    public categories: any[] = [];

    constructor(private route: ActivatedRoute, private webcastsService: WebcastsService, private router: Router, private alertService: AlertService, private translateService: TranslateService, private _location: Location) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.webcastId = params.id;
                if (this.webcastId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.webcastsService.allCategories().subscribe((result) => {
                this.categories = result;
            })
        );
    }

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.webcast = new Webcast();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.subscriptions.push(
            this.webcastsService.webcastdetail(this.webcastId).subscribe(
                (result) => {
                    this.webcast = result;
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );
    }

    save() {
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.webcastsService.addwebcast(this.webcast).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("webcasts.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.webcastsService.updatewebcast(this.webcast).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("webcasts.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    back() {
        this._location.back();
    }
}
