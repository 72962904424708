import { Component, OnInit } from "@angular/core";
import { AlertService, BaseComponent } from "@impacgroup/angular-baselib";
import { InterfaceSettingsService } from "./interfacesettings.service";
import { TranslateService } from "@ngx-translate/core";
import { InterfaceSettings } from "../../api-models/InterfaceSettings";

@Component({
    selector: "app-interfacesettings",
    templateUrl: "./interfacesettings.component.html",
    styleUrls: ["interfacesettings.component.scss"]
})
export class InterfaceSettingsComponent extends BaseComponent implements OnInit {
    public interfaceSettings: InterfaceSettings;

    constructor(private interfaceSettingsService: InterfaceSettingsService, private alertService: AlertService, private translateService: TranslateService) {
        super();

        this.subscriptions.push(
            this.interfaceSettingsService.interfaceSettingsDetail().subscribe(
                (result) => {
                    this.interfaceSettings = result;
                },
                (err) => {
                    console.log(err);
                }
            )
        );
    }

    ngOnInit(): void {}

    save() {
        this.subscriptions.push(
            this.interfaceSettingsService.updateInterfaceSettings(this.interfaceSettings).subscribe((result) => {
                this.alertService.addSuccess(this.translateService.instant("interfacesettings.success"));
                this.interfaceSettings = result;
            })
        );
    }
}
