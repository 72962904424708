import { Component, OnInit } from "@angular/core";
import { AppService } from "../../../app.service";
import { AlertService } from "@impacgroup/angular-baselib";

@Component({
  selector: "app-layout",
  templateUrl: "layout.component.html",
  styleUrls: ["layout.component.scss"]
})
export class FullLayoutComponent implements OnInit {
  maTheme: string = this.service.maTheme;

  constructor(public service: AppService, public alertService: AlertService) {}

  ngOnInit() {
  }
}
