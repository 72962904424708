import { Component, OnInit, Host } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { WebcastsService } from "./webcasts.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { Webcast } from "../../api-models/Webcast";
import { WebcastsMainComponent } from "./webcasts.main.component";

@Component({
    selector: "app-webcasts",
    templateUrl: "./webcasts.component.html",
    styleUrls: ["webcasts.component.scss"]
})
export class WebcastsComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: Webcast[] = [];

    constructor(
        private webcastsService: WebcastsService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        @Host() parent: WebcastsMainComponent
    ) {
        super(translateService, {
            stateSaveMode: webcastsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_webcasts_list"
        });

        this.dtOptions.order = [[4, "asc"]];
        this.UTCDATEFORMAT = this.webcastsService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.webcastsService.webcasts(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "title", orderable: true },
            { data: "category", orderable: false },
            { data: "qsdnId", orderable: true },
            { data: "sortNumber", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    addWebcast() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    editWebcast(id: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    deleteWebcast(id: string) {
        const initialState = {
            title: this.translateService.instant("webcasts.list.delete.title"),
            message: this.translateService.instant("webcasts.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.webcastsService.deletewebcast(id).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("webcasts.list.delete.success"));
                    this.rerenderDatatable();
                })
            );
        };
    }
}
