import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { Webcast } from "../../api-models/Webcast";

export interface IWebcastConfigService {
    webcastsAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const WebcastConfigService = new InjectionToken<IWebcastConfigService>("WebcastConfig");

@Injectable()
export class WebcastsService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(WebcastConfigService) private webcastConfig: IWebcastConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.webcastConfig.utcDateFormat;
        this.datatableStateSaveMode = this.webcastConfig.datatableStateSaveMode;
    }

    public webcasts(params: IApiListQueryParameter): Observable<{ list: Webcast[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getWebcastsServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(Webcast, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public webcastdetail(id: string): Observable<Webcast> {
        return this.http
            .get<Object>(this.getWebcastsServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Webcast, result)));
    }

    public addwebcast(webcast: Webcast): Observable<Webcast> {
        return this.http
            .post<Object>(this.getWebcastsServiceUrl(""), classToPlain(webcast), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Webcast, result)));
    }

    public updatewebcast(webcast: Webcast): Observable<Webcast> {
        return this.http
            .patch<Object>(this.getWebcastsServiceUrl(webcast._id), classToPlain(webcast), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Webcast, result)));
    }

    public deletewebcast(id: string): Observable<Webcast> {
        return this.http
            .delete<Object>(this.getWebcastsServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Webcast, result)));
    }

    public allCategories(): Observable<
        Array<{
            _id: string;
            name: string;
        }>
    > {
        return this.http
            .get<any[]>(this.getWebcastsServiceUrl("categories"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(
                map((result) => {
                    return result.map((cat) => {
                        return {
                            _id: cat._id,
                            name: cat.name
                        };
                    });
                })
            );
    }

    private getWebcastsServiceUrl(postfix: string): string {
        return this.webcastConfig.webcastsAPI + postfix;
    }
}
