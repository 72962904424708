import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { InterfaceSettings } from "../../api-models/InterfaceSettings";

export interface IInterfaceSettingsConfigService {
    interfaceSettingsAPI: string;
}

export const InterfaceSettingsConfigService = new InjectionToken<IInterfaceSettingsConfigService>("InterfaceSettingsConfig");

@Injectable()
export class InterfaceSettingsService {
    constructor(@Inject(InterfaceSettingsConfigService) private interfaceSettingsConfig: IInterfaceSettingsConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    public interfaceSettingsDetail(): Observable<InterfaceSettings> {
        return this.http
            .get<Object>(this.getInterfaceSettingsServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(InterfaceSettings, result)));
    }

    public updateInterfaceSettings(interfaceSettings: InterfaceSettings): Observable<InterfaceSettings> {
        return this.http
            .patch<Object>(this.getInterfaceSettingsServiceUrl(""), classToPlain(interfaceSettings), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(InterfaceSettings, result)));
    }

    private getInterfaceSettingsServiceUrl(postfix: string): string {
        return this.interfaceSettingsConfig.interfaceSettingsAPI + postfix;
    }
}
