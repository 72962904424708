import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { CategoriesService } from "./categories.service";
import { TranslateService } from "@ngx-translate/core";
import { Category } from "../../api-models/Category";

@Component({
    selector: "app-categorydetail",
    templateUrl: "./categorydetail.component.html",
    styleUrls: ["categorydetail.component.scss"]
})
export class CategoryDetailComponent extends BaseComponent implements OnInit {
    private categoryId: string = null;
    public category: Category = null;

    constructor(private route: ActivatedRoute, private categoriesService: CategoriesService, private router: Router, private alertService: AlertService, private translateService: TranslateService, private _location: Location) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.categoryId = params.id;
                if (this.categoryId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.category = new Category();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.subscriptions.push(
            this.categoriesService.categorydetail(this.categoryId).subscribe(
                (result) => {
                    this.category = result;
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );
    }

    save() {
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.categoriesService.addcategory(this.category).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("categories.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.categoriesService.updatecategory(this.category).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("categories.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    back() {
        this._location.back();
    }
}
