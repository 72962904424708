/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/buttons";
import * as i4 from "@angular/forms";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./navigation-trigger/navigation-trigger.component.ngfactory";
import * as i7 from "./navigation-trigger/navigation-trigger.component";
import * as i8 from "../../app.service";
import * as i9 from "./logo/logo.component.ngfactory";
import * as i10 from "./logo/logo.component";
import * as i11 from "ngx-bootstrap/dropdown";
import * as i12 from "ngx-bootstrap/component-loader";
import * as i13 from "@angular/animations";
import * as i14 from "@angular/router";
import * as i15 from "./header.component";
import * as i16 from "@impacgroup/angular-oauth-base";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "label", [], [[2, "active", null], [1, "aria-pressed", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.maCurrentTheme = $event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.setMaTheme() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(3, 1), i1.ɵdid(4, 81920, null, 0, i3.ButtonRadioDirective, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.ButtonRadioGroupDirective], i1.Renderer2], { btnRadio: [0, "btnRadio"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ButtonRadioDirective]), i1.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _ck(_v, 3, 0, ("btn bg-" + _v.context.$implicit)); _ck(_v, 2, 0, currVal_9); var currVal_10 = _v.context.$implicit; _ck(_v, 4, 0, currVal_10); var currVal_11 = _co.maCurrentTheme; _ck(_v, 6, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).isActive; var currVal_1 = i1.ɵnov(_v, 4).isActive; var currVal_2 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 8).ngClassValid; var currVal_7 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "dropdown-menu dropdown-menu-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "dropdown-item theme-switch"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "btn-group btn-group--colors"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.maThemes; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("global.themeswitch")); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("global.logout")); _ck(_v, 8, 0, currVal_2); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navigation-trigger", [], null, null, null, i6.View_NavigationTriggerComponent_0, i6.RenderType_NavigationTriggerComponent)), i1.ɵdid(2, 114688, null, 0, i7.NavigationTriggerComponent, [i8.AppService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-logo", [], null, null, null, i9.View_LogoComponent_0, i9.RenderType_LogoComponent)), i1.ɵdid(4, 114688, null, 0, i10.LogoComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 9, "ul", [["class", "top-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 8, "li", [["class", "hidden-xs-down dropdown"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i11.BsDropdownState, i11.BsDropdownState, []), i1.ɵdid(8, 212992, null, 0, i11.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i12.ComponentLoaderFactory, i11.BsDropdownState, i11.BsDropdownConfig, i13.AnimationBuilder], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [["dropdownToggle", ""]], [[1, "target", 0], [8, "href", 4], [1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i14.RouterLinkWithHref, [i14.Router, i14.ActivatedRoute, i2.LocationStrategy], null, null), i1.ɵdid(11, 147456, null, 0, i11.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i11.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i11.BsDropdownState], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "zmdi zmdi-more-vert"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(14, 16384, null, 0, i11.BsDropdownMenuDirective, [i11.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).dropup; var currVal_1 = i1.ɵnov(_v, 8).isOpen; var currVal_2 = (i1.ɵnov(_v, 8).isOpen && i1.ɵnov(_v, 8).isBs4); _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 10).target; var currVal_4 = i1.ɵnov(_v, 10).href; var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 11).isDisabled; var currVal_7 = i1.ɵnov(_v, 11).isOpen; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i15.HeaderComponent, [i8.AppService, i16.AuthService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i15.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
