import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { CategoriesService } from "./categories.service";
import { TranslateService } from "@ngx-translate/core";
import { Category } from "../../api-models/Category";
var CategoryDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CategoryDetailComponent, _super);
    function CategoryDetailComponent(route, categoriesService, router, alertService, translateService, _location) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.categoriesService = categoriesService;
        _this.router = router;
        _this.alertService = alertService;
        _this.translateService = translateService;
        _this._location = _location;
        _this.categoryId = null;
        _this.category = null;
        _this.subscriptions.push(_this.route.params.subscribe(function (params) {
            _this.categoryId = params.id;
            if (_this.categoryId) {
                _this.initEditMode();
            }
            else {
                _this.initAddMode();
            }
        }));
        return _this;
    }
    CategoryDetailComponent.prototype.ngOnInit = function () { };
    CategoryDetailComponent.prototype.initAddMode = function () {
        this.MODE = BaseComponentMode.ADD;
        this.category = new Category();
    };
    CategoryDetailComponent.prototype.initEditMode = function () {
        var _this = this;
        this.MODE = BaseComponentMode.EDIT;
        this.subscriptions.push(this.categoriesService.categorydetail(this.categoryId).subscribe(function (result) {
            _this.category = result;
        }, function (err) {
            _this.router.navigate(["../"], { relativeTo: _this.route });
        }));
    };
    CategoryDetailComponent.prototype.save = function () {
        var _this = this;
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(this.categoriesService.addcategory(this.category).subscribe(function (result) {
                _this.alertService.addSuccess(_this.translateService.instant("categories.detail.add.success"));
                _this.router.navigate(["../"], { relativeTo: _this.route });
            }));
        }
        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(this.categoriesService.updatecategory(this.category).subscribe(function (result) {
                _this.alertService.addSuccess(_this.translateService.instant("categories.detail.edit.success"));
                _this.router.navigate(["../"], { relativeTo: _this.route });
            }));
        }
    };
    CategoryDetailComponent.prototype.back = function () {
        this._location.back();
    };
    return CategoryDetailComponent;
}(BaseComponent));
export { CategoryDetailComponent };
