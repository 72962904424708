/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./page-loader.component";
import * as i5 from "../../global/api-handling/HttpLoadingService";
var styles_PageLoaderComponent = [i0.styles];
var RenderType_PageLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageLoaderComponent, data: { "animation": [{ type: 7, name: "togglePageLoader", definitions: [{ type: 0, name: "true", styles: { type: 6, styles: { transform: "translate3d(0,0,0)" }, offset: null }, options: undefined }, { type: 0, name: "void", styles: { type: 6, styles: { transform: "translate3d(0,50px,0)" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: { type: 4, styles: null, timings: "300ms" }, options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: null, timings: "300ms" }, options: null }], options: {} }] } });
export { RenderType_PageLoaderComponent as RenderType_PageLoaderComponent };
function View_PageLoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "page-loader"]], [[24, "@togglePageLoader", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["viewBox", "25 25 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:circle", [["cx", "50"], ["cy", "50"], ["fill", "none"], ["r", "20"], ["stroke-miterlimit", "10"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("global.pageloader")); _ck(_v, 3, 0, currVal_1); }); }
export function View_PageLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageLoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PageLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-loader", [], null, null, null, View_PageLoaderComponent_0, RenderType_PageLoaderComponent)), i1.ɵdid(1, 245760, null, 0, i4.PageLoaderComponent, [i5.HttpLoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageLoaderComponentNgFactory = i1.ɵccf("app-page-loader", i4.PageLoaderComponent, View_PageLoaderComponent_Host_0, {}, {}, []);
export { PageLoaderComponentNgFactory as PageLoaderComponentNgFactory };
