import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { AlertService, BaseComponent } from "@impacgroup/angular-baselib";
import { InterfaceSettingsService } from "./interfacesettings.service";
import { TranslateService } from "@ngx-translate/core";
var InterfaceSettingsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InterfaceSettingsComponent, _super);
    function InterfaceSettingsComponent(interfaceSettingsService, alertService, translateService) {
        var _this = _super.call(this) || this;
        _this.interfaceSettingsService = interfaceSettingsService;
        _this.alertService = alertService;
        _this.translateService = translateService;
        _this.subscriptions.push(_this.interfaceSettingsService.interfaceSettingsDetail().subscribe(function (result) {
            _this.interfaceSettings = result;
        }, function (err) {
            console.log(err);
        }));
        return _this;
    }
    InterfaceSettingsComponent.prototype.ngOnInit = function () { };
    InterfaceSettingsComponent.prototype.save = function () {
        var _this = this;
        this.subscriptions.push(this.interfaceSettingsService.updateInterfaceSettings(this.interfaceSettings).subscribe(function (result) {
            _this.alertService.addSuccess(_this.translateService.instant("interfacesettings.success"));
            _this.interfaceSettings = result;
        }));
    };
    return InterfaceSettingsComponent;
}(BaseComponent));
export { InterfaceSettingsComponent };
