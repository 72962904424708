import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../app.service";
import { AuthService, OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { BaseComponent } from "@impacgroup/angular-baselib";
var HeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HeaderComponent, _super);
    function HeaderComponent(service, authService, router) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.authService = authService;
        _this.router = router;
        // Retrieve current theme from AppService
        _this.maCurrentTheme = service.maTheme;
        // Available themes
        _this.maThemes = ["green", "blue", "red", "teal", "cyan", "blue-grey", "purple", "indigo"];
        return _this;
    }
    // Set theme
    HeaderComponent.prototype.setMaTheme = function () {
        this.service.maTheme = this.maCurrentTheme;
    };
    HeaderComponent.prototype.ngOnInit = function () { };
    HeaderComponent.prototype.logout = function () {
        var _this = this;
        this.subscriptions.push(this.authService.logout().subscribe(function (result) {
            OAuthStorageAdapter.clearStorage();
            _this.router.navigate(["/login"]);
        }));
    };
    return HeaderComponent;
}(BaseComponent));
export { HeaderComponent };
