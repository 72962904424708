import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, OAuthStorageAdapter, SwitchOrganizationDialogComponent } from "@impacgroup/angular-oauth-base";
import { BaseComponent } from "@impacgroup/angular-baselib";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
var UserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserComponent, _super);
    function UserComponent(oauthStorageAdapter, authService, router, translateService, modalService) {
        var _this = _super.call(this) || this;
        _this.oauthStorageAdapter = oauthStorageAdapter;
        _this.authService = authService;
        _this.router = router;
        _this.translateService = translateService;
        _this.modalService = modalService;
        _this.userMenu = false;
        _this.email = "";
        _this.organization = "";
        _this.email = _this.oauthStorageAdapter.getOAuthLoginResult().email;
        _this.organization = _this.oauthStorageAdapter.getOAuthLoginResult().organization;
        return _this;
    }
    UserComponent.prototype.ngOnInit = function () { };
    UserComponent.prototype.switch = function () {
        var initialState = {
            title: this.translateService.instant("switchorganization.title"),
            message: this.translateService.instant("switchorganization.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        var bsModalRef = this.modalService.show(SwitchOrganizationDialogComponent, { initialState: initialState });
        bsModalRef.content.confirmCallback = function () {
            window.location.reload();
        };
    };
    UserComponent.prototype.logout = function () {
        var _this = this;
        this.subscriptions.push(this.authService.logout().subscribe(function (result) {
            OAuthStorageAdapter.clearStorage();
            _this.router.navigate(["/login"]);
        }));
    };
    UserComponent.prototype.editProfile = function () {
        this.router.navigate(["/profile"]);
    };
    return UserComponent;
}(BaseComponent));
export { UserComponent };
