import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var HttpLoadingService = /** @class */ (function () {
    function HttpLoadingService() {
        this.httpRequestsSubject = new BehaviorSubject(0);
        this.httpRequests = this.httpRequestsSubject.asObservable();
    }
    HttpLoadingService.prototype.increment = function () {
        this.httpRequestsSubject.next(this.httpRequestsSubject.getValue() + 1);
    };
    HttpLoadingService.prototype.decrement = function () {
        this.httpRequestsSubject.next(this.httpRequestsSubject.getValue() > 0 ? this.httpRequestsSubject.getValue() - 1 : 0);
    };
    HttpLoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpLoadingService_Factory() { return new HttpLoadingService(); }, token: HttpLoadingService, providedIn: "root" });
    return HttpLoadingService;
}());
export { HttpLoadingService };
