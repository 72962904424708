/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/ngx-bootstrap/alert/ngx-bootstrap-alert.ngfactory";
import * as i4 from "ngx-bootstrap/alert";
import * as i5 from "@angular/router";
import * as i6 from "./layout.component";
import * as i7 from "@impacgroup/angular-baselib";
var styles_SingleLayoutComponent = [i0.styles];
var RenderType_SingleLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SingleLayoutComponent, data: {} });
export { RenderType_SingleLayoutComponent as RenderType_SingleLayoutComponent };
function View_SingleLayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col-12 col-md-6 offset-md-3 animated"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "alert", [], null, [[null, "onClosed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClosed" === en)) {
        var pd_0 = (_co.alertService.onAlertClosed(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i1.ɵdid(4, 114688, null, 0, i4.AlertComponent, [i4.AlertConfig, i1.ChangeDetectorRef], { type: [0, "type"], dismissible: [1, "dismissible"] }, { onClosed: "onClosed" }), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "col-12 col-md-6 offset-md-3 animated"; var currVal_1 = _v.context.$implicit.animationClass; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.type; var currVal_3 = "true"; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.msg; _ck(_v, 5, 0, currVal_4); }); }
function View_SingleLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "alert_message_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleLayoutComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertService.alerts; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SingleLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleLayoutComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.alertService.alerts.length > 0); _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_SingleLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_SingleLayoutComponent_0, RenderType_SingleLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i6.SingleLayoutComponent, [i7.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SingleLayoutComponentNgFactory = i1.ɵccf("app-layout", i6.SingleLayoutComponent, View_SingleLayoutComponent_Host_0, {}, {}, []);
export { SingleLayoutComponentNgFactory as SingleLayoutComponentNgFactory };
