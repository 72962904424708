import * as tslib_1 from "tslib";
import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";
var Webcast = /** @class */ (function () {
    function Webcast() {
    }
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        Exclude({ toPlainOnly: true }),
        tslib_1.__metadata("design:type", Date)
    ], Webcast.prototype, "created", void 0);
    tslib_1.__decorate([
        Type(nonLambdaReturn(Date)),
        Exclude({ toPlainOnly: true }),
        tslib_1.__metadata("design:type", Date)
    ], Webcast.prototype, "updated", void 0);
    return Webcast;
}());
export { Webcast };
