import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../app.service";
import { AuthService, OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { BaseComponent } from "@impacgroup/angular-baselib";

@Component({
    selector: "app-header",
    templateUrl: "header.component.html",
    styleUrls: ["header.component.scss"]
})
export class HeaderComponent extends BaseComponent implements OnInit {
    maCurrentTheme: string;
    maThemes: Array<string>;

    constructor(private service: AppService, private readonly authService: AuthService, private readonly router: Router) {
        super();

        // Retrieve current theme from AppService
        this.maCurrentTheme = service.maTheme;

        // Available themes
        this.maThemes = ["green", "blue", "red", "teal", "cyan", "blue-grey", "purple", "indigo"];
    }

    // Set theme
    setMaTheme() {
        this.service.maTheme = this.maCurrentTheme;
    }

    ngOnInit() {}

    public logout() {
        this.subscriptions.push(
            this.authService.logout().subscribe((result) => {
                OAuthStorageAdapter.clearStorage();
                this.router.navigate(["/login"]);
            })
        );
    }
}
