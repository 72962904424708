import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";

export class InterfaceSettings {
    _id?: string;

    showFooterHotlineText: boolean;
    showSponsorsPage: boolean;
    showOnDemandPage: boolean;

    showProgramRegistrationDay1: boolean;
    showProgramLiveJoinDay1: boolean;
    showProgramRegistrationDay2: boolean;
    showProgramLiveJoinDay2: boolean;
    showProgramRegistrationDay3: boolean;
    showProgramLiveJoinDay3: boolean;

    version?: number;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}
