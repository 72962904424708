import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { WebcastsService } from "./webcasts.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { WebcastsMainComponent } from "./webcasts.main.component";
var WebcastsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WebcastsComponent, _super);
    function WebcastsComponent(webcastsService, translateService, router, route, modalService, alertService, parent) {
        var _this = _super.call(this, translateService, {
            stateSaveMode: webcastsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_webcasts_list"
        }) || this;
        _this.webcastsService = webcastsService;
        _this.translateService = translateService;
        _this.router = router;
        _this.route = route;
        _this.modalService = modalService;
        _this.alertService = alertService;
        _this.UTCDATEFORMAT = "";
        _this.rows = [];
        _this.dtOptions.order = [[4, "asc"]];
        _this.UTCDATEFORMAT = _this.webcastsService.UTCDATEFORMAT;
        return _this;
    }
    WebcastsComponent.prototype.ngOnInit = function () { };
    WebcastsComponent.prototype._dataTableTrigger = function () {
        var _this = this;
        return function (dataTableParameters, callback) {
            // get api query parameters by datatable
            var queryParams = _this.getApiQueryParamsByDataTable(dataTableParameters);
            _this.subscriptions.push(_this.webcastsService.webcasts(queryParams).subscribe(function (result) {
                _this.rows = result.list;
                callback({
                    recordsTotal: result.total,
                    recordsFiltered: result.count,
                    data: []
                });
            }));
        };
    };
    WebcastsComponent.prototype._dataTableColums = function () {
        return [
            { data: "title", orderable: true },
            { data: "category", orderable: false },
            { data: "qsdnId", orderable: true },
            { data: "sortNumber", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    };
    WebcastsComponent.prototype.addWebcast = function () {
        this.router.navigate(["add"], { relativeTo: this.route });
    };
    WebcastsComponent.prototype.editWebcast = function (id) {
        this.router.navigate([id], { relativeTo: this.route });
    };
    WebcastsComponent.prototype.deleteWebcast = function (id) {
        var _this = this;
        var initialState = {
            title: this.translateService.instant("webcasts.list.delete.title"),
            message: this.translateService.instant("webcasts.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        var bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState: initialState });
        bsModalRef.content.confirmCallback = function () {
            _this.subscriptions.push(_this.webcastsService.deletewebcast(id).subscribe(function (result) {
                _this.alertService.addSuccess(_this.translateService.instant("webcasts.list.delete.success"));
                _this.rerenderDatatable();
            }));
        };
    };
    return WebcastsComponent;
}(BaseDatatableComponent));
export { WebcastsComponent };
