<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ 'webcasts.detail.add.title' | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ 'webcasts.detail.edit.title' | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i>
                {{ 'global.buttons.back' | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="webcast" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12">

                    <div class="form-group">
                        <label>{{ 'webcasts.props.title' | translate }}</label>
                        <input name="title" type="text" class="form-control" [(ngModel)]="webcast.title" #title="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && title.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'webcasts.detail.validation.title' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-12">

                    <div class="form-group">
                        <label>{{ 'webcasts.props.description' | translate }}</label>
                        <input name="description" type="text" class="form-control" [(ngModel)]="webcast.description" #description="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && description.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'webcasts.detail.validation.description' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-12">

                    <div class="form-group">
                        <label>{{ 'webcasts.props.qsdnId' | translate }}</label>
                        <input name="qsdnId" type="text" class="form-control" [(ngModel)]="webcast.qsdnId" #qsdnId="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && qsdnId.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'webcasts.detail.validation.qsdnId' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'webcasts.props.category' | translate }}</label>
                        <ng-select
                            name="category"
                            [items]="categories"
                            bindLabel="name"
                            bindValue="_id"
                            placeholder=""
                            [(ngModel)]="webcast.category" 
                            #category="ngModel" [ngClass]="{ 'is-invalid': f.submitted && category.invalid }" required>
                        </ng-select>
                        <div class="invalid-tooltip">
                            {{ 'webcasts.detail.validation.category' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group">
                        <label>{{ 'webcasts.props.sortNumber' | translate }}</label>
                        <input name="sortNumber" type="number" class="form-control" [(ngModel)]="webcast.sortNumber" #sortNumber="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && sortNumber.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'webcasts.detail.validation.sortNumber' | translate }}
                        </div>
                    </div>

                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i
                            class="zmdi zmdi-check"></i> {{ 'global.buttons.save' | translate }}</button>
                </div>

            </form>
        </div>
    </div>
</div>