import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { WebcastsComponent } from "./webcasts.component";
import { WebcastsService, WebcastConfigService, IWebcastConfigService } from "./webcasts.service";
import { WebcastDetailComponent } from "./webcastdetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { WebcastsMainComponent } from "./webcasts.main.component";

@NgModule({
    declarations: [WebcastsMainComponent, WebcastsComponent, WebcastDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class WebcastsModule {
    static forRoot(webcastConfig: IWebcastConfigService): ModuleWithProviders {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: WebcastsModule,
            providers: [
                WebcastsService,
                {
                    provide: WebcastConfigService,
                    useValue: webcastConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: WebcastsMainComponent,
                children: [
                    {
                        path: "",
                        component: WebcastsComponent
                    },
                    {
                        path: "add",
                        component: WebcastDetailComponent
                    },
                    {
                        path: ":id",
                        component: WebcastDetailComponent
                    }
                ]
            }
        ];
    }
}
