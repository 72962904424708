import { Exclude, Type } from "class-transformer";
import { nonLambdaReturn } from "@impacgroup/angular-baselib";
import { Category } from "./Category";

export class Webcast {
    _id?: string;

    title: string;
    description: string;
    qsdnId: string;
    sortNumber: number;
    category: Category | string | null; // => { _id: string, name: string } on list endpoint

    version?: number;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(nonLambdaReturn(Date))
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}
