import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { Category } from "../../api-models/Category";

export interface ICategoryConfigService {
    categoriesAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const CategoryConfigService = new InjectionToken<ICategoryConfigService>("CategoryConfig");

@Injectable()
export class CategoriesService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(CategoryConfigService) private categoryConfig: ICategoryConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.categoryConfig.utcDateFormat;
        this.datatableStateSaveMode = this.categoryConfig.datatableStateSaveMode;
    }

    public categories(params: IApiListQueryParameter): Observable<{ list: Category[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getCategoriesServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(Category, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public categorydetail(id: string): Observable<Category> {
        return this.http
            .get<Object>(this.getCategoriesServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Category, result)));
    }

    public addcategory(category: Category): Observable<Category> {
        return this.http
            .post<Object>(this.getCategoriesServiceUrl(""), classToPlain(category), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Category, result)));
    }

    public updatecategory(category: Category): Observable<Category> {
        return this.http
            .patch<Object>(this.getCategoriesServiceUrl(category._id), classToPlain(category), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Category, result)));
    }

    public deletecategory(id: string): Observable<Category> {
        return this.http
            .delete<Object>(this.getCategoriesServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Category, result)));
    }

    private getCategoriesServiceUrl(postfix: string): string {
        return this.categoryConfig.categoriesAPI + postfix;
    }
}
