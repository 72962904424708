/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@impacgroup/angular-oauth-base";
import * as i4 from "@angular/common";
import * as i5 from "./user.component";
import * as i6 from "@angular/router";
import * as i7 from "ngx-bootstrap/modal";
var styles_UserComponent = [i0.styles];
var RenderType_UserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserComponent, data: { "animation": [{ type: 7, name: "toggleUserMenu", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { height: "0", opacity: "0" }, offset: null }, options: undefined }, { type: 0, name: "true", styles: { type: 6, styles: { height: "*", opacity: "1" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: { type: 4, styles: null, timings: "200ms ease-in" }, options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: null, timings: "200ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_UserComponent as RenderType_UserComponent };
function View_UserComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("global.switch")); _ck(_v, 1, 0, currVal_0); }); }
function View_UserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "user__menu"]], [[24, "@toggleUserMenu", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editProfile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.ɵa, [i1.TemplateRef, i1.ViewContainerRef, i3.PermissionService], { appHasPermission: [0, "appHasPermission"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "GLOBAL"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userMenu; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("global.editprofile")); _ck(_v, 2, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("global.logout")); _ck(_v, 7, 0, currVal_3); }); }
export function View_UserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "user__info"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.userMenu = !_co.userMenu) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "user__name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "user__email"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.userMenu; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userMenu; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.organization; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.email; _ck(_v, 5, 0, currVal_2); }); }
export function View_UserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar-user", [], null, null, null, View_UserComponent_0, RenderType_UserComponent)), i1.ɵdid(1, 245760, null, 0, i5.UserComponent, [i3.OAuthStorageAdapter, i3.AuthService, i6.Router, i2.TranslateService, i7.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserComponentNgFactory = i1.ɵccf("app-sidebar-user", i5.UserComponent, View_UserComponent_Host_0, {}, {}, []);
export { UserComponentNgFactory as UserComponentNgFactory };
